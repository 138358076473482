import React, { FC, Fragment, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdditionalPaymentsItem,
  AdditionalPaymentsList,
  convertPaymentMethodToText,
  Currency,
  formatDate,
  Modal,
  offsets,
  Typography
} from '@xq/ui-kit';
import { SidemenuContext, SidemenuContextData } from '@context';
import { DASH } from '@constants';
import { InvoiceItem } from '../../pages';

interface AdditionalPaymentsModalProps {
  saveAdditionalPayment(): void;
  shownInvoice: InvoiceItem;
  shownAdditionalPayment: AdditionalPaymentsItem[];
  setShownAdditionalPayment: (value: AdditionalPaymentsItem[]) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  currencyIso3: Currency;
}

export const AdditionalPaymentsModal: FC<AdditionalPaymentsModalProps> = (
  props
) => {
  const {
    currencyIso3,
    shownInvoice,
    saveAdditionalPayment,
    shownAdditionalPayment,
    setShownAdditionalPayment,
    isModalOpen,
    setIsModalOpen
  } = props;
  const { t } = useTranslation();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  const isFieldsFilled: boolean = useMemo(() => {
    return shownAdditionalPayment?.reduce((result, item) => {
      return (
        result &&
        !!item.service &&
        !!item.from &&
        !!item.to &&
        typeof item.price === 'number' &&
        !isNaN(item.price)
      );
    }, true);
  }, [shownAdditionalPayment]);

  return (
    <Fragment>
      {isModalOpen && (
        <Modal
          title={'Additional payments'}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          cancelText={t('common.cancel')}
          submitText={t('common.save')}
          isSubmitDisabled={!isFieldsFilled}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={saveAdditionalPayment}
          maxHeight="637px"
          maxWidth="1000px"
        >
          <Typography
            className={offsets['mt-40']}
            variant="system"
            element="div"
          >
            {t('organizations.organization')}:{' '}
            {sidemenuContext?.currentOrganization?.name || DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('common.license')}: {shownInvoice?.licenseName || DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('organizations.invoiceDate')}:{' '}
            {shownInvoice?.date ? formatDate(shownInvoice.date) : DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('organizations.paymentMethod')}:{' '}
            {convertPaymentMethodToText(shownInvoice?.paymentMethod) || DASH}
          </Typography>

          <AdditionalPaymentsList
            className={offsets['mt-40']}
            additionalPayments={shownAdditionalPayment}
            onChange={setShownAdditionalPayment}
            currency={currencyIso3}
            translations={{
              delete: t('common.delete'),
              service: t('common.service'),
              comment: t('common.comment'),
              price: t('common.price'),
              amountOfUsers: t('common.amountOfUsers')
            }}
          />
        </Modal>
      )}
    </Fragment>
  );
};

AdditionalPaymentsModal.displayName = 'AdditionalPaymentsModal';
