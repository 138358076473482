import { handleBackendError, omniApiConfiguration } from '@services';
import { ListParams } from 'interfaces';
import { ManagerList } from './dataTypes';
import {
  OldLmsMigratorApi,
  OrganizationListDTOResponse,
  OrganizationListSortOptionsDTOFieldNameEnum,
  OrganizationListSortOptionsDTOOrderEnum,
  OrganizationManagerSelectItemListDTOResponse,
  OrganizationsListPageApi
} from '@xq/omni-gateway-frontend-client';

export interface OrganizationsService {
  fetchOrganizations(params: ListParams): Promise<OrganizationListDTOResponse>;

  fetchManagers(): Promise<ManagerList[]>;

  setOrganizationManager(
    organizationId: string,
    newManagerId: string
  ): Promise<void>;

  migrateOrganization(organizationId: string): Promise<void>;
}

const omniOrganizationsGateway = new OrganizationsListPageApi(
  omniApiConfiguration
);

const migrateGateway = new OldLmsMigratorApi(omniApiConfiguration);

export class OrganizationsServiceApi implements OrganizationsService {
  async fetchOrganizations(
    params: ListParams
  ): Promise<OrganizationListDTOResponse> {
    try {
      return await omniOrganizationsGateway.organizationListPageControllerGetOrganizationList(
        {
          searchString: params?.searchValue,
          organizationListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as OrganizationListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as OrganizationListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchOrganizations.name
      );
    }
  }

  async fetchManagers(): Promise<ManagerList[]> {
    try {
      const res: OrganizationManagerSelectItemListDTOResponse =
        await omniOrganizationsGateway.organizationListPageControllerGetOrganizationManagerList();
      return res.items;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchManagers.name
      );
    }
  }

  async setOrganizationManager(
    organizationId: string,
    newManagerId: string
  ): Promise<void> {
    try {
      await omniOrganizationsGateway.organizationListPageControllerSetOrganizationManager(
        {
          organizationManagerDTORequest: {
            managerUuid: newManagerId,
            organizationUuid: organizationId
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }

  async migrateOrganization(organizationId: string): Promise<void> {
    try {
      await migrateGateway.oldLmsMigratorControllerMigrateOrganization({
        organizationUuid: organizationId
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }
}
