import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import {
  BillingInvoiceAdditionalDetails,
  BillingInvoiceDetails,
  InvoiceDetailsPageData
} from '@pages';
import { BillingInvoiceDetailsPageApi } from '@xq/omni-gateway-frontend-client';
import { AdditionalPaymentsItem, Currency } from '@xq/ui-kit';

export interface InvoiceDetailsService {
  fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData>;

  fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<AdditionalPaymentsItem[]>;

  saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: AdditionalPaymentsItem[]
  ): Promise<void>;
}

const omniInvoiceDetailsGateway = new BillingInvoiceDetailsPageApi(
  omniApiConfiguration
);

export class InvoiceDetailsServiceApi implements InvoiceDetailsService {
  async fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData> {
    try {
      const response =
        await omniInvoiceDetailsGateway.billingInvoiceDetailsPageControllerGetPageData(
          {
            billingInvoiceUuid: invoiceUuid
          }
        );

      const invoiceDetails: BillingInvoiceDetails[] =
        response.invoiceDetails.map((detail) => ({
          ...detail,
          billingInvoiceUuid: invoiceUuid
        }));

      return {
        ...response,
        invoiceDetails,
        currencyIso3: response?.currencyIso3 as Currency
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<BillingInvoiceAdditionalDetails[]> {
    try {
      const response =
        await omniInvoiceDetailsGateway.billingInvoiceDetailsPageControllerGetInvoiceAdditionalPayments(
          {
            billingInvoiceUuid: invoiceUuid
          }
        );
      return response?.map((el) => {
        return { ...el, price: el.totalPayment };
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchAdditionalPayments.name
      );
    }
  }

  async saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: AdditionalPaymentsItem[]
  ): Promise<void> {
    try {
      const payments = additionalPayments?.map((el) => {
        return {
          uuid: el?.uuid,
          service: el?.service,
          comment: el?.comment,
          total: el?.price,
          from: el?.from,
          to: el?.to,
          amountOfUsers: el?.amountOfUsers
        };
      });

      return await omniInvoiceDetailsGateway.billingInvoiceDetailsPageControllerSaveInvoiceAdditionalPayments(
        {
          billingInvoiceUuid: invoiceUuid,
          billingInvoiceDetailsListDTORequestSave: {
            additionalPayments: payments
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.saveAdditionalPayments.name
      );
    }
  }
}

const invoiceDetailsMock: BillingInvoiceDetails[] = [
  {
    uuid: '1',
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    service: 'ModelTree: investment management',
    comment: 'Recalculation of regular license payment for previous period',
    createdAt: new Date('2022-11-19T18:32:04.000Z'),
    from: new Date('2022-11-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    amountOfUsers: null,
    totalPayment: 2000,
    prepayment: null,
    isEditable: false,
    isAdditionalPayment: true
  },
  {
    uuid: '2',
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    service: 'ModelTree: investment management',
    comment: 'Regular license payment',
    createdAt: new Date('2022-12-19T18:32:04.000Z'),
    from: new Date('2022-11-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    amountOfUsers: 10,
    totalPayment: 12000,
    prepayment: 6000,
    isEditable: true,
    isAdditionalPayment: false
  }
];

const pageDataMock: InvoiceDetailsPageData = {
  invoice: {
    uuid: '1',
    licenseName: 'ModelTree: investment management',
    date: new Date('2022-12-19T18:32:04.000Z'),
    from: new Date('2021-12-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    billingFrequency: 'ANNUALLY',
    paymentMethod: 'BEFORE',
    licensePayments: 20000,
    additionalPayments: 5000,
    totalPayments: 25000,
    canBeDeleted: false
  },
  currencyIso3: 'EUR',
  invoiceDetails: invoiceDetailsMock
};

const additionalPaymentsMock: BillingInvoiceAdditionalDetails[] = [
  {
    uuid: '1',
    service: 'Data out service',
    comment: 'For data integration',
    totalPayment: 1200,
    isEditable: false
  },
  {
    uuid: '2',
    service: 'Reporting plugin (ppt/word/excel)',
    comment: 'For data integration',
    totalPayment: 1200,
    isEditable: true
  }
];

export class InvoiceDetailsServiceMock implements InvoiceDetailsService {
  async fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData> {
    try {
      await wait(1000);
      return pageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<BillingInvoiceAdditionalDetails[]> {
    try {
      await wait(1000);
      return additionalPaymentsMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchAdditionalPayments.name
      );
    }
  }

  async saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: BillingInvoiceAdditionalDetails[]
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.saveAdditionalPayments.name
      );
    }
  }
}
