import React, { FC, useEffect, useRef, useState } from 'react';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import {
  Chips,
  ComponentProps,
  Icon,
  SelectItem,
  Typography
} from '@components';
import cn from 'classnames';
import styles from './MultiselectWithTabs.module.scss';
import CustomDropdown from '@components/MultiselectWithTabs/components/CustomDropdown';

type TagRender = SelectProps['tagRender'];

export interface MultiSelectWithTabsProps extends ComponentProps {
  /**
   * Label
   */
  label: string;
  /**
   * Options for autocomplete
   */
  options: { value: string; label: string }[];
  /**
   * Filters for multiselect
   */
  filters: {
    title: string;
    value: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
  /**
   * Called when value of an Input changed
   */
  onChange: (value: SelectItem[]) => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies that options are loading
   */
  isLoading?: boolean;
  /**
   * Specifies that an Input should be required
   */
  required?: boolean;
  /**
   * Shows error message
   */
  errorMessage?: string;
  value: string;
  searchString: SelectItem;
  onSave: (value: SelectItem) => void;
  savedSearches: { label: string; value: string[]; id: string }[];
  onApplyClick: () => void;
  onCustomSelectClick: (state: boolean, value: string, label: string) => void;
  onDeleteTagClick: ({ value }: { value: string }) => void;
  onSelectValue: (value: string) => void;
  handleOnLoadSave: (value: { value: string[]; label: string }) => void;
  filterActiveItems: string[];
  selectedValues: (string | { value: string; label: string })[];
  onResetClick: () => void;
  handleSearchDelete: (searchId: string) => void;
}

export const MultiselectWithTabs: FC<MultiSelectWithTabsProps> = (props) => {
  const {
    id,
    style,
    dataTestId,
    disabled,
    isLoading,
    searchString,
    options,
    filters,
    onSave,
    savedSearches,
    onApplyClick,
    onCustomSelectClick,
    onDeleteTagClick,
    onSelectValue,
    handleOnLoadSave,
    filterActiveItems,
    selectedValues,
    onResetClick,
    handleSearchDelete
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const selectRef = useRef(null);
  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleResetClick = () => {
    if (onResetClick) {
      onResetClick();
    }

    if (isSearchMode) {
      setIsSearchMode(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    const handleClick = (event: MouseEvent) => {
      const wrapper = wrapperRef.current;
      const dropdown = dropdownRef.current;

      if (wrapper && wrapper.contains(event.target as Node)) {
        if ((event.target as HTMLElement).tagName === 'INPUT') {
          onResetClick();
        }
      } else if (dropdown && dropdown.contains(event.target as Node)) {
        return;
      } else {
        setIsSearchMode(false);
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  const handleSearch = (value: string) => {
    if (value) {
      setIsSearchMode(true);
    }
  };

  const handleApplyClick = () => {
    onApplyClick();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSearchMode) {
      document.documentElement.style.setProperty('--placeholder-padding', '0');
    } else {
      document.documentElement.style.setProperty(
        '--placeholder-padding',
        '15px'
      );
    }
  }, [isSearchMode]);

  const handleOnSelect = (value: string | undefined) => {
    if (value === undefined) {
      handleResetClick();
    } else {
      onSelectValue(value);
      setIsSearchMode(false);

      if (value.length > 0) {
        handleApplyClick();
      }
    }
  };

  const tagRender: TagRender = (props) => {
    const { value } = props;

    return (
      <Chips
        className={styles.chips}
        item={{
          value,
          label: props.label as string
        }}
        onClose={onDeleteTagClick}
        color="#121212"
        bgColor="rgba(20, 38, 83, 0.2)"
        closeIconColor="#121212"
      />
    );
  };

  const isAutocompleteSelected = () => {
    return selectedValues.some((selected) => {
      const selectedValue =
        typeof selected === 'string' ? selected : selected?.value;
      return options.some((option) => option?.value === selectedValue);
    });
  };

  const onDropdownOpen = (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsOpen(true);

    isAutocompleteSelected() && setIsSearchMode(true);
  };

  const selectedValueRender = () => {
    const isSaved = savedSearches.find(
      (item) => item.label === searchString.label
    );
    return (
      <div className={styles.selectedValueContainer}>
        <div onClick={onDropdownOpen} className={styles.selectedValue}>
          <Typography variant="button-third">{searchString.label}</Typography>
        </div>
        <div onClick={() => onSave(searchString)}>
          <Icon
            size="xs"
            name="save"
            className={styles.selectedValueIcon}
            style={{ fill: isSaved ? '#F06516' : 'defaultColor' }}
          />
        </div>
      </div>
    );
  };

  if (!isOpen) {
    return (
      <>
        {searchString?.value ? (
          selectedValueRender()
        ) : (
          <div
            onClick={onDropdownOpen}
            className={styles.closedSearchContainer}
          >
            <Typography variant="button-third" className={styles.searchValue}>
              All Items
            </Typography>
            <Icon color="disabled" name="filter" size="xs" />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      id={id}
      ref={wrapperRef}
      data-testid={dataTestId}
      className={cn(styles.searchContainer, 'multiselect-with-tabs', {
        [styles.searchMode]: isSearchMode,
        [styles.chipsMode]: !isSearchMode && selectedValues.length > 0
      })}
    >
      <Icon
        className={cn(styles.searchIcon, {
          [styles.disabled]: !isSearchMode && selectedValues.length > 0
        })}
        color="default"
        name="search"
        size="s"
      />
      <Select
        ref={selectRef}
        disabled={disabled}
        rootClassName={styles.root}
        className={styles.select}
        suffixIcon={null}
        onClear={handleResetClick}
        dropdownStyle={{
          borderRadius: 0,
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          boxShadow: '0 1px 2px 0 rgba(18, 18, 18, 0.2)'
        }}
        {...(isSearchMode
          ? {
              allowClear: {
                clearIcon: (
                  <Icon
                    name="close"
                    size="s"
                    style={{
                      fill: '#121212'
                    }}
                  />
                )
              }
            }
          : {})}
        open={isOpen}
        loading={isLoading}
        autoFocus={isSearchMode}
        showSearch
        onSearch={handleSearch}
        value={isSearchMode ? selectedValues[0] : selectedValues}
        placeholder="All Items"
        {...(isSearchMode ? {} : { mode: 'multiple' })}
        style={style}
        defaultActiveFirstOption={false}
        bordered={false}
        tagRender={tagRender}
        onChange={handleOnSelect}
        dropdownAlign={{ offset: [0, 10] }}
        notFoundContent={<span>No results found</span>}
        optionRender={(oriOption) => {
          return (
            <div className={styles.option}>
              <div className={styles.optionDot} />
              <Typography className={styles.optionValue} variant="body-5">
                {oriOption.data.label}
              </Typography>
            </div>
          );
        }}
        dropdownRender={(menu) => {
          return isSearchMode ? (
            menu
          ) : (
            <div ref={dropdownRef}>
              <CustomDropdown
                onSelect={onCustomSelectClick}
                items={filters}
                activeItems={filterActiveItems}
                onResetClick={handleResetClick}
                onLoadSave={handleOnLoadSave}
                onApplyClick={handleApplyClick}
                savedSearches={savedSearches}
                handleSearchDelete={handleSearchDelete}
              />
            </div>
          );
        }}
        options={options}
      />
    </div>
  );
};

MultiselectWithTabs.displayName = 'MultiselectWithTabs';
