import React, { ReactElement } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  TabsComponent as Tabs,
  Typography
} from '@components';
import styles from './CustomDropdown.module.scss';
import { colors } from '@componentsStyles';
import './rc-tabs-overwrite.css';

interface Props {
  /** List of tabs */
  items: {
    title: string;
    value: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
  /** Active tab key */
  activeKey?: string;

  /** Active filter items keys */
  activeItems: string[];
  /** Tab bar gutter */
  tabBarGutter?: number;
  /**
   * Called when clicked on next button
   */
  handleNextClick?: () => void;
  /**
   * Called when clicked on prev button
   */
  handleBackClick?: () => void;
  /**
   * Called when tab is clicked
   */
  handleClickTab?: (key: string) => void;
  /**
   * Variant of the tabs
   */

  onSelect: (state: boolean, value: string, label: string) => void;
  onLoadSave: (value: { value: string[]; label: string }) => void;
  onResetClick: () => void;
  onApplyClick: () => void;
  savedSearches: { label: string; value: string[]; id: string }[];
  handleSearchDelete: (searchId: string) => void;
}

function CustomDropdown({
  items,
  onSelect,
  onLoadSave,
  activeItems,
  onResetClick,
  onApplyClick,
  savedSearches = [],
  handleSearchDelete
}: Props): ReactElement {
  const handleOnChange = (state: boolean, value: string, label: string) => {
    onSelect(state, value, label);
  };

  const handleSearchClick = (search: { value: string[]; label: string }) => {
    onLoadSave(search);
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.filters}>
        <Tabs
          className={styles.filters_tabs}
          activeKey={items[0].value}
          childrenClassName={styles.tabs_content}
          items={items.map((item) => ({
            key: item.value,
            label: item.title,
            children: item.children.map((el) => (
              <div key={el.value}>
                <Checkbox
                  checked={activeItems && activeItems.includes(el.value)}
                  onChange={(state) => {
                    handleOnChange(state, el.value, el.label);
                  }}
                  // defaultChecked={activeItems && activeItems.includes(el.value)}
                  label={el.label}
                />
              </div>
            ))
          }))}
        />
      </div>
      <div className={styles.saved_searches}>
        {savedSearches.length > 0 && (
          <div className={styles.search}>
            <div className={styles.search_header}>
              <Icon
                size="xs"
                name="save"
                className={styles.search_header_icon}
              />
              <Typography
                variant="system-heading-2"
                color={colors.gray40Color}
                className={styles.search_header_title}
              >
                Saved Searches
              </Typography>
            </div>
            <div className={styles.search_list}>
              {savedSearches.map((search, index) => (
                <div className={styles.wrapper} key={index}>
                  <span
                    className={styles.search_list_filter}
                    key={index}
                    onClick={() => handleSearchClick(search)}
                  >
                    {search.label}
                  </span>
                  <Icon
                    onClick={() => handleSearchDelete(search.id)}
                    className={styles.remove}
                    name="trash"
                    color="default"
                    size="s"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <span onClick={onResetClick} className={styles.footer_reset}>
          Reset
        </span>
        <Button
          disabled={activeItems.length === 0}
          onClick={onApplyClick}
          className={styles.footer_apply}
          type="third"
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default CustomDropdown;
